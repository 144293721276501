$phone: 640px;
$tablet: 768px;
$desktop: 1024px;

#maintain {
  min-height: 200vh;
  width: 100vw;

  .maintain-hero {
    min-height: 100vh;
    position: relative;
    padding: 8rem 3rem;
    gap: 9rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #393939;
    @media screen and (min-width: $tablet) {
      gap: 2rem;
      justify-content: center;
      align-items: center;
    }
    img {
      position: absolute;
      width: 100vw;
      height: 100vh;
      left: 0;
      top: 0;
      mix-blend-mode: luminosity;
      object-fit: cover;
      object-position: center;
      opacity: 0.09;
    }
    .logo {
      transition: all 0.5s;
      position: relative;
      top: 25%;
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: $tablet) {
        width: 50%;
        position: absolute;
      }
      h1 {
        font-family: "MicroGramma";
        margin-top: 0.5rem;
        width: 70%;
        font-size: 1.5rem;
        text-align: center;
        color: white;
        text-transform: capitalize;
        @media screen and (min-width: $tablet) {
          font-size: 3rem;
        }
      }
      @media screen and (min-width: $tablet) {
        &.fixed {
          top: 0;
          transform: scale(0.3);
        }
      }
    }
    .info {
      transition: all 0.5s;
      position: relative;
      width: 90%;
      @media screen and (min-width: $tablet) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform: translateY(90%);
      }

      h2 {
        font-weight: 100;
        text-transform: capitalize;
        color: white;
        text-align: left;
        font-size: 1.95rem;
        font-family: "NeueHaas";
        @media screen and (min-width: $tablet) {
          text-align: center;
          font-size: 4rem;
        }
      }
      p {
        font-family: "NeueHaas";
        width: 100%;
        margin-top: 1rem;
        font-weight: 100;
        text-transform: capitalize;
        color: white;
        text-align: left;
        font-size: 1.15rem;
        line-height: 1.75rem;
        @media screen and (min-width: $tablet) {
          text-align: center;
          font-size: 1.5rem;
          line-height: 2.5rem;
          width: 75%;
        }
      }
      @media screen and (min-width: $tablet) {
        &.fixed {
          transform: translateY(0);
        }
      }
    }
    .to-contact {
      position: absolute;
      bottom: 3rem;
      left: 50%;
      transform: translateX(-50%);
      width: fit-content;
      text-align: center;
      @media screen and (min-width: $tablet) {
        bottom: -20px;
      }
      h6 {
        font-family: "NeueHaas";
        font-weight: 100;
        color: white;
        font-size: 1rem;
        text-transform: capitalize;
        @media screen and (min-width: $tablet) {
          opacity: 0;
        }
      }
      svg {
        cursor: pointer;
        font-size: 1rem;
        color: white;
        transition: all 0.25s;

        &:hover {
          color: #f58320;
        }
        @media screen and (min-width: $tablet) {
          font-size: 1rem;
          font-weight: 100;
          margin-bottom: 0;
        }
      }
    }
  }
  .maintain-contact {
    position: relative;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(180deg, #1e1e1e 0%, #000000 100%);
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 80%;
      height: 50%;
      text-align: center;
      padding-left: 20%;
      padding-right: 20%;

      h2 {
        font-family: "NeueHaas";
        text-transform: capitalize;
        font-weight: 100;
        color: white;
        margin-bottom: 3rem;
        font-size: 2rem;
        @media screen and (min-width: $tablet) {
          font-size: 4rem;
        }
      }
      .contact-items {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media screen and (min-width: $tablet) {
          flex-direction: row;
          gap: 10px;
          width: 50%;
        }
        .contact-item {
          position: relative;
          display: flex;
          align-items: flex-start;
          width: 100%;
          @media screen and (min-width: $tablet) {
            width: 25%;
            height: fit-content;
          }

          &:hover {
            svg {
              color: #f58320;
            }
            @media screen and (min-width: $tablet) {
              p {
                left: -30%;
                top: 120%;
                opacity: 1;
              }
            }
          }
          svg {
            transition: all 0.25s;
            width: 20%;
            height: 100%;
            color: white;
            background-color: #262626;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            @media screen and (min-width: $tablet) {
              width: 100%;
              padding: 15px;
            }
          }
          p {
            font-family: "NeueHaas";
            transition: all 0.25s;
            position: relative;
            color: white;
            vertical-align: middle;
            width: 80%;
            font-size: 0.85rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (min-width: $tablet) {
              display: inline-block;
              position: absolute;
              width: fit-content;
              left: 0;
              font-size: 1.25rem;
              top: 100%;
              opacity: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .creator {
      position: absolute;
      bottom: 1rem;
      width: 30px;
      height: 30px;
      svg {
        color: white;
        height: 100%;
        width: 100%;
      }
    }
  }
}
