*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: MicroGramma;
  src: url("../assets/fonts/MicrogrammaStd-MdExtended.otf") format("opentype");
}

@font-face {
  font-family: NeueHaas;
  src: url("../assets/fonts/NeueHaasDisplayThin.ttf");
}

body {
  font-family: "Sirin Stencil", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
